<template>
  <div>
    <section class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
      <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
        <div class="tw-col-1 tw-justify-center tw-flex tw-flex-col">
          <h1 class="tw-font-serif tw-text-red tw-text-4rem">Mango & Lola</h1>
          <h2 class="tw-mt-3 tw-font-serif tw-text-gray-n3 tw-text-2xl">for couples</h2>
          <p class="tw-my-6 tw-w-3/4 tw-text-black">Creating your own Couples account on Mango & Lola is fast, easy and
            free!</p>
          <router-link
            class="tw-inline-flex tw-no-underline tw-self-start tw-items-center tw-px-14 tw-py-4 tw-border tw-bg-red tw-leading-4 tw-font-semibold tw-rounded-md tw-text-white"
            :to="{ name: 'feed' }">
            Start exploring
          </router-link>
        </div>
        <div class="tw-col-1">
          <img class="tw-rounded-lg tw-object-cover tw-object-center" src="@/assets/img/landing/couples/1.jpg" alt="">
        </div>
      </div>
    </section>

    <section class="tw-bg-gray tw-mt-14 tw-text-left tw-py-16">
      <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
        <h3 class="tw-font-semibold tw-text-2rem tw-text-black">For couples</h3>
        <p class="tw-my-6 tw-w-3/4 tw-text-black">Our user-friendly platform was designed by a wedding planner for newly
          engaged couples to help you easily plan your dream wedding. We teamed up with the best software engineers to
          create a full- service online wedding brand helping you easily book your vendors with a click of a button.</p>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-mt-8">
      <AppFeat :feats="feats" />
    </section>

    <section class="tw-bg-gray tw-mt-14 tw-text-left tw-py-16 tw-text-black">
      <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
        <h3 class="tw-font-semibold tw-text-2rem">Benefits</h3>
        <p class="tw-my-6 tw-w-4/6">
          We are passionate about weddings & have helped many couples successfully plan their dream weddings with
          confidence.
        </p>
        <div class="lg:tw-grid lg:tw-grid-cols-3 tw-gap-x-6 tw-gap-y-7">
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">A private account</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">Direct access to vendor availability calendar</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">Process invoices and payments via platform</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">Discover wedding vendors</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">Direct access to booking online</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">Reminder when payments are due</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">Direct access to vendor prices</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">Custom budget feature</h5>
          </div>
          <div
            class="tw-col-1 tw-flex tw-flex-col tw-p-8 tw-bg-white tw-rounded-md tw-border tw-border-gray-light2 tw-justify-center tw-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.66663 7.33325V4.66659C4.66663 3.78253 5.01782 2.93468 5.64294 2.30956C6.26806 1.68444 7.1159 1.33325 7.99996 1.33325C8.88401 1.33325 9.73186 1.68444 10.357 2.30956C10.9821 2.93468 11.3333 3.78253 11.3333 4.66659V7.33325"
                stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h5 class="tw-mt-8">All vendors and payments in one place</h5>
          </div>
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-py-16">
      <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
        <div class="tw-col-1 tw-justify-center tw-flex tw-flex-col">
          <h4 class="tw-text-black tw-text-2rem tw-leading-relaxed">Download the mobile app for even better experience.
          </h4>
          <div class="tw-mt-8 tw-flex tw-space-x-4">
            <a href="#">
              <img src="@/assets/img/landing/play_store.svg" alt="">
            </a>
            <a href="#">
              <img src="@/assets/img/landing/app_store.svg" alt="">
            </a>
          </div>
        </div>
        <div class="tw-col-1">
          <img class="tw-rounded-lg tw-object-cover tw-object-center" src="@/assets/img/landing/app_screenshot.png"
            alt="">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppFeat from "@/components/ApplicationFeatures";

export default {
  components: {
    AppFeat,
  },
  layout: 'basic',

  metaInfo() {
    return { title: 'home' }
  },

  data: () => ({
    dialogInvestor: false,
    dialogLegal: false,
    dialogJobs: false,
    dialogMarketing: false,
    dialogFaq: false,
    dialogContact: false,
    title: process.env.VUE_APP_TITLE,
    legals: [
      {
        title: 'Privacy Policy',
        description: ''
      },
      {
        title: 'Terms and Conditions',
        description: ''
      },
      {
        title: 'Copyright Policy',
        description: ''
      },
    ],
    faqs: [
      {
        title: 'How can I book a Vendor on M&L? ',
        description: 'If you’re ready to book a wedding professional on Mango & Lola when browsing Vendor’s offerings, you will encounter either “Auto-Booking” or “Booking Request”. '
      },
      {
        title: 'Auto-Booking?',
        description: 'Couples can book a Vendor instantly (without vendor approval), as long as the wedding is in the Vendor’s “area of coverage”, couples can book them “Auto-Booking”. You will be able to see the booking immediately in your account under “My Vendors”. '
      },
      {
        title: 'Booking request?',
        description: 'With this booking option, a Vendor has 48 hours to accept the job. Mango and Lola allows 48 hours for the vendor to reply, but most do reply within a few hours. Once a Vendor approves your booking request, it will appear in your account under “My Vendors”. '
      },
      {
        title: 'Custom quote?',
        description: 'If you would like to hire a Vendor, but do not see what you are looking for in their offerings, then you can submit a Custom Quote. This Vendor will create a custom quote for you, and you will be able to find it in your account under “quote requests”. You will have 48 hours to accept the quote offer, or quote offer will expire. Once you accept your quote offer, it will become a booking, and you will be able to see it in your account under “My Vendors”.'
      },
      {
        title: 'Customer service question? ',
        description: 'If you have a question, please contact us at support@mangolola.com'
      },
      {
        title: 'Account management question?',
        description: 'For couple accounts please contact us at caccounts@mangolola.com. For vendor accounts please contact us at vaccounts@mangolola.com'
      },

    ],
    articles: [
      {
        src: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        title: 'Mobile first & Responsive',
        text: 'Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.',
      },
      {
        src: 'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        title: 'Think outside the box',
        text: 'Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.',
      },
      {
        src: 'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80',
        title: 'Small changes, big difference',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
    ],
    features: [
      {
        icon: 'mdi-account-group-outline',
        title: 'Full service online',
        text: 'An easy to navigate wedding platform giving couples tools to browse/book all wedding pros.',
      },
      {
        icon: 'mdi-update',
        title: 'Transparency',
        text: 'Vendor\'s available dates & rates displayed. Option to directly book on site.',
      },
      {
        icon: 'mdi-shield-outline',
        title: 'Secure online booking site',
        text: 'All payments are kept entirely safe giving you peace of mind. Quick and easy online booking. Everything is handled online. Removing the opportunity for double bookings.',
      },
      {
        icon: 'mdi-shield-outline',
        title: 'PLATFORM FOR (PARTNERS) VENDORS',
        text: 'Showcase best work with photos & client reviews. Manage rates and availability. Stay up to date with reservations. Process invoices and payments.',
      },
    ],
    stats: [
      ['24k', 'Vendors'],
      ['330+', 'Booking per day'],
      ['1m', 'Views'],
      ['5m', 'Comments'],
    ],
    feats: [
      "Vendor prices",
      "Vendor availability calendar",
      "Online booking",
    ],
  }),
  mounted() {
    ///  this.$http.get("sanctum/csrf-cookie");
  },
  computed: mapGetters({
    authenticated: 'auth/authenticated',
    user: 'auth/user',
    check: 'auth/check',

  })
}


</script>

<style scoped>
.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.title {
  font-size: 85px;
}
</style>
