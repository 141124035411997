<template>
  <li class="tw-flex tw-items-center">
    <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
    <slot></slot>
  </li>
</template>

<script>
  import {CheckCircleIcon} from 'vue-feather-icons';
export default {
  components:{
    CheckCircleIcon,
  },
  name:'AppFeatItem',
}
</script>