<template>
  <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
    <div class="tw-col-1">
      <img class="tw-rounded-lg tw-object-cover tw-object-center" src="@/assets/img/landing/couples/1.jpg" alt="">
    </div>
    <div class="tw-col-1 tw-justify-center tw-flex tw-flex-col">
      <h3 class="tw-font-semibold tw-text-2rem tw-text-black">Where we stand apart</h3>
      <p class="tw-my-6 tw-text-black">
        ...from everyone else is that we enable you to:
      </p>
      <ul class="tw-list-none tw-pl-0 tw-space-y-6 tw-text-black">
        <AppFeatItem v-for="(feat, index) in feats" :key="index">{{feat}}</AppFeatItem>
      </ul>
    </div>
  </div>
</template>

<script>
import AppFeatItem from "@/components/ApplicationFeatureItem";
export default {
  components:{
    AppFeatItem,
  },
  props:[
    'feats',
  ],
}
</script>
